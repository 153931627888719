<template>
  <div id="hatch-chart">
    <Hero/>
    <div class="hatch-chart-section">
      <h1>Hatches</h1>
      <img src="../assets/hatch_chart.png">
    </div>
    <BookNow/>
  </div>
</template>

<script>
import Hero from '@/components/Hero.vue'
import BookNow from '@/components/BookNow.vue'
export default {
  components: { 
    Hero, 
    BookNow
  }
}
</script>


<style lang="scss">
#hatch-chart {
  .hatch-chart-section {
    width: 65%;
    margin: auto;
    h1 {
      text-align: left;
      margin-top: 50px;
      margin-bottom: 50px;
    }
    img {
      width: 100%;
      border: 3px solid black;
    }
  }
  @media only screen and (max-width: 500px) {
    .hatch-chart-section {
      width: 90%;
      margin: auto;
    }
  }
}
</style>